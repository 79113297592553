.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 15px;
    background-color: transparent;
    border: 2px solid #ffffff;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    cursor: pointer;
    transition:
        color 0.3s ease,
        box-shadow 0.3s ease,
        transform 0.2s ease;
    box-sizing: border-box;
}

.Button__icon {
    margin-right: 8px;
    font-size: 1.5rem;
    color: #ffffff;
    transition: color 0.3s ease;
    display: inline-flex;
}

.Button:hover {
    color: #FFD700;
    border-color: #FFD700;
    box-shadow: 0 0 8px 2px rgba(255, 215, 0, 0.7);
}

.Button:hover .Button__icon {
    color: #FFD700;
}

.Button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

@media (max-width: 600px) {
    .Button {
        width: 100%;
        padding: 10px 15px;
    }
}

/* Add more variant styles as needed */
.Button-withIcon {
    /* Add any specific styles for the withIcon variant */
}