/* ComingSoon.css */

.ComingSoonPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    overflow: hidden;
    background-color: black;
    /* Set the background to black */
}

.Title {
    color: #ffffff;
    font-size: 3rem;
    text-align: center;
    margin: 20px 0;
}

.Subtitle {
    color: #ffffff;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 40px;
}

.NotifyContainer {
    display: flex;
    flex-direction: row;
    /* Arrange input and button side by side by default */
    align-items: center;
    /* Vertically center the items */
    gap: 15px;
    /* Space between input and button */
    flex-wrap: wrap;
    /* Allow wrapping on smaller screens */
}

.EmailInput {
    padding: 10px 15px;
    border: 2px solid #ffffff;
    border-radius: 5px;
    font-size: 1rem;
    background-color: transparent;
    color: #ffffff;
    transition: border-color 0.3s ease, background-color 0.3s ease;
    flex: 1;
    /* Allow the input to grow */
    min-width: 250px;
    /* Minimum width for better appearance */
    box-sizing: border-box;
    /* Include padding and border in element's total width and height */
}

.EmailInput::placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.EmailInput:focus {
    border-color: #FFD700;
    /* Gold border on focus */
    background-color: rgba(255, 215, 0, 0.1);
    /* Light gold background on focus */
}

.NotifyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the content vertically and horizontally */
    padding: 8px 15px;
    /* Adjusted padding to match input's vertical padding */
    background-color: transparent;
    /* Transparent fill */
    border: 2px solid #ffffff;
    /* Colored border */
    border-radius: 5px;
    color: #ffffff;
    /* Colored text */
    font-size: 1rem;
    cursor: pointer;
    transition:
        color 0.3s ease,
        box-shadow 0.3s ease,
        /* Transition for box-shadow */
        transform 0.2s ease;
    /* Transition for scaling */
    box-sizing: border-box;
    /* Include padding and border in element's total width and height */
    /* Removed height: 100%; to prevent stretching */
}

.BellIcon {
    margin-right: 8px;
    font-size: 1.5rem;
    color: #ffffff;
    transition: color 0.3s ease;
    display: inline-flex;
    /* Ensure proper alignment with motion */
}

.NotifyButton:hover {
    color: #FFD700;
    /* Change text color to gold on hover */
    border-color: #FFD700;
    /* Change border color to gold on hover */
    box-shadow: 0 0 8px 2px rgba(255, 215, 0, 0.7);
    /* Add glowing gold shadow */
}

.NotifyButton:hover .BellIcon {
    color: #FFD700;
    /* Change icon color to gold on hover */
}

.NotifyButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.ThankYouMessage {
    color: #FFD700;
    /* Gold color for the thank-you message */
    font-size: 1.2rem;
    margin-top: 20px;
    text-align: center;
}

/* Responsive Design: Stack input above button on smaller screens */
@media (max-width: 600px) {
    .NotifyContainer {
        flex-direction: column;
        /* Stack vertically */
        align-items: stretch;
        /* Stretch to full width */
    }

    .EmailInput {
        width: 100%;
        /* Full width on mobile */
    }

    .NotifyButton {
        width: 100%;
        /* Full width on mobile */
        padding: 10px 15px;
        /* Ensure padding matches input */
    }
}

.FilterFMLogo {
    width: 150px;
    height: 150px;
    top: 20px;
    /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    object-fit: contain;
    /* This ensures the logo maintains its aspect ratio */
}